import React from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../../../context/account";
import FilterContext from "../../../context/filter";
import { DatePickerComponent } from "../../cores/date-picker/date-picker";
import { DropdownComponent } from "../../cores/dropdown/dropdown";
import Switch from "react-switch";
import style from "./header.module.scss";

type Props = {
  user: any;
};
export const HeaderComponent = ({ user }: Props) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "team",
    "coach",
    "recording",
  ]);
  const { logout } = React.useContext(AccountContext);
  //const { isCoach, setIsCoach } = React.useContext(CoachContext);
  const [isOpen, setIsOpen] = React.useState([
    { name: "coach", value: false },
    { name: "recording", value: false },
  ]);

  const { startDate, setStartDate, endDate, setEndDate } =
    React.useContext(FilterContext);
  const updateIsOpen = (name: string, value: boolean) => {
    const result = isOpen.map((item) => {
      item.name === name && (item.value = value);
      return item;
    });
    setIsOpen(result);
  };
  const getIsOpen = (name: string) => {
    const result = isOpen.find((item) => item.name === name);
    return result;
  };
  const checkPassword = (value: string, name: "coach" | "recording") => {
    if (value === "0000") {
      setCookie(name, { value: true });
      updateIsOpen(name, false);
    }
  };
  const onSwitchClick = (name: "coach" | "recording") => {
    if (cookies[name]?.value) {
      setCookie(name, { value: false });
      updateIsOpen(name, false);
    } else {
      updateIsOpen(name, true);
    }
  };
  const onLogout = () => {
    logout();
    removeCookie("team");
    removeCookie("coach");
    removeCookie("recording");
    navigate("/login");
  };
  const setTeam = (team: any) => {
    setCookie("team", { name: team.name, id: team.id });
  };

  React.useEffect(() => {
    if (!cookies.team?.id) {
      setTeam({ name: "All", id: "" });
    }
  }, [pathname]);

  return (
    <div className={style["header"]}>
      <div className={style["header-left"]}>
        {user &&
          pathname !== "/" &&
          !pathname.includes("/squad/") &&
          !pathname.includes("/sessions/") && (
            <DropdownComponent
              title={cookies.team ? cookies.team.name : ""}
              contentPosition="right"
            >
              <ul>
                <li onClick={() => setTeam({ name: "All", id: "" })}>All</li>
                {user.teams?.map((team: any, key: number) => (
                  <li key={key} onClick={() => setTeam(team)}>
                    {team.name}
                  </li>
                ))}
              </ul>
            </DropdownComponent>
          )}
        {!pathname.includes("/sessions/") && pathname !== "/" && (
          <DatePickerComponent
            contentPosition="right"
            dateRange={{
              startDate: startDate,
              endDate: endDate,
            }}
            onChange={(range) => {
              setStartDate(range.startDate);
              setEndDate(range.endDate);
            }}
            onReset={() => {
              setStartDate(undefined);
              setEndDate(undefined);
            }}
          />
        )}
      </div>
      <div className={style["header-right"]}>
        {/* {pathname !== "/" && (
          <ButtonComponent
            title="Export"
            icon="/icons/export.svg"
            variant="transparent"
            position="left"
          />
        )} */}
        {user && (
          <DropdownComponent title={user.firstName} isProfile hasPadding>
            <ul className={style["header-profile"]}>
              <li>
                User: <b>{user.firstName + " " + user.lastName}</b>
              </li>
              <li className={style["header-profile-coach"]}>
                <div className={style["header-profile-coach-switch"]}>
                  Coach
                  <Switch
                    onChange={() => onSwitchClick("coach")}
                    checked={
                      cookies.coach && cookies.coach.value
                        ? cookies.coach.value
                        : false
                    }
                    height={18}
                    width={42}
                    handleDiameter={19}
                    offColor={"#dadada"}
                    onColor={"#333333"}
                    offHandleColor={"#000000"}
                    onHandleColor={"#000000"}
                  />
                </div>
                {getIsOpen("coach")?.value && (
                  <input
                    placeholder="enter password"
                    type={"password"}
                    onChange={(e) => checkPassword(e.target.value, "coach")}
                  />
                )}
              </li>
              <li>
                <span
                  onClick={() => navigate("/admin")}
                  style={{ cursor: "pointer" }}
                >
                  Admin panel
                </span>
              </li>
              <li className={style["header-profile-coach"]}>
                <div className={style["header-profile-coach-switch"]}>
                  Synchronization
                  <Switch
                    onChange={() => onSwitchClick("recording")}
                    checked={
                      cookies.recording && cookies.recording.value
                        ? cookies.recording.value
                        : false
                    }
                    height={18}
                    width={42}
                    handleDiameter={19}
                    offColor={"#dadada"}
                    onColor={"#333333"}
                    offHandleColor={"#000000"}
                    onHandleColor={"#000000"}
                  />
                </div>
                {getIsOpen("recording")?.value && (
                  <input
                    placeholder="enter password"
                    type={"password"}
                    onChange={(e) => checkPassword(e.target.value, "recording")}
                  />
                )}
              </li>
              <li>
                <span onClick={onLogout} style={{ cursor: "pointer" }}>
                  Logout
                </span>
              </li>
            </ul>
          </DropdownComponent>
        )}
      </div>
    </div>
  );
};
