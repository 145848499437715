import React from "react";
import style from "./audio-sync.module.scss";

interface ICustomInput {
  onChange: (value: string) => void;
  startAt: number;
}
export const CustomInput = ({ onChange, startAt }: ICustomInput) => {
  const getValues = () => {
    // startAt > 0 && console.log(startAt);
    const sec = startAt / 1000;
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec % 3600) / 60);
    let seconds = Math.floor((sec % 3600) % 60);
    let milliFormat = String(startAt % 1000).padStart(3, "0");
    let milliseconds = Math.floor(parseInt(milliFormat));
    return {
      hours: hours ? hours + "" : "0",
      minutes: minutes < 10 ? "0" + minutes : minutes,
      seconds: seconds < 10 ? "0" + seconds : seconds,
      milliseconds: milliseconds < 10 ? "0" + milliseconds : milliseconds,
    };
  };
  const [hours, setHours] = React.useState(getValues().hours);
  const [minutes, setMinutes] = React.useState(getValues().minutes);
  const [seconds, setSeconds] = React.useState(getValues().seconds);
  const [milliseconds, setMilliseconds] = React.useState(
    getValues().milliseconds
  );
  const [millisecondsValue, setMillisecondsValue] = React.useState(
    getValues().milliseconds
  );
  React.useEffect(() => {
    const time = `${(hours ?? 0).toString().padStart(2, "0")}:${(minutes ?? 0)
      .toString()
      .padStart(2, "0")}:${(seconds ?? 0).toString().padStart(2, "0")}.${
      milliseconds ?? 0
    }`;
    onChange(time);
  }, [hours, minutes, seconds, milliseconds]);
  return (
    <div className={style["custom-input"]}>
      <input
        type="number"
        name="hours"
        value={hours}
        onChange={(e: any) => setHours(e.target.value)}
      />
      <b>:</b>
      <input
        type="number"
        name="minutes"
        value={minutes}
        onChange={(e: any) => setMinutes(e.target.value)}
      />
      <b>:</b>
      <input
        type="number"
        name="seconds"
        value={seconds}
        onChange={(e: any) => setSeconds(e.target.value)}
      />
      <b>.</b>
      <input
        type="number"
        name="milliseconds"
        value={millisecondsValue}
        onChange={(e: any) => {
          e.target.value < 10
            ? setMilliseconds(e.target.value * 100)
            : setMilliseconds(e.target.value * 10);
          e.target.value > 99 && setMilliseconds(e.target.value);
          setMillisecondsValue(e.target.value);
        }}
      />
    </div>
  );
};
