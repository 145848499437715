import style from "./audio-icon.module.scss";
export const AudioIcon = () => {
  return (
    <div className={style["audio-icon"]}>
      {[...Array(8)].map((item, i) => (
        <span key={i} className={style["audio-icon-" + i]} />
      ))}
    </div>
  );
};
