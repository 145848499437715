import React from "react";
import { ReactSVG } from "react-svg";
import style from "./audio-player.module.scss";
import AudioVisualizer from "./audio-visualizer";
import { IAudio } from "../../../types/modules/field-overview";
import { AudioIcon } from "../audio-icon/audio-icon";

interface IAudioPlayerComponent {
  isPlaying: boolean;
  currentTime: number;
  isMuted?: boolean;
  audios: IAudio[];
  coachNumber?: number;
  realTime: number;
  speed: number;
}
export const AudioPlayerComponent = (props: IAudioPlayerComponent) => {
  const getAudio = () => {
    return props.audios.find(
      (item: any) =>
        props.currentTime >= item.startOffset &&
        props.currentTime <= item.endOffset
    );
  };
  const playerRef = React.useRef<any>();
  const [audio, setAudio] = React.useState<any>(getAudio());
  const [isLoaded, setIsLoaded] = React.useState(false);

  const onVideoPlay = () => {
    if (props.isPlaying && getAudio()?.startOffset) {
      const newAudio = getAudio();
      updateTime(newAudio);
      playerRef.current
        .play()
        .then(props.isPlaying)
        .catch(() => "");
    } else {
      playerRef.current.pause();
    }
  };
  const updateTime = (newAudio: any) => {
    //console.log(props.currentTime);
    playerRef.current.currentTime =
      (props.realTime * 1000 - newAudio?.startOffset) / 1000;
  };
  const getUpdateTime = () => {
    const newAudio = getAudio();
    if (newAudio) {
      if (playerRef.current) {
        updateTime(newAudio);
      }
      setAudio(newAudio);
    } else {
      setAudio(undefined);
    }
  };
  React.useEffect(() => {
    if (playerRef.current && isLoaded) {
      onVideoPlay();
    }
  }, [props.isPlaying, props.isMuted]);

  React.useEffect(() => {
    getUpdateTime();
  }, [props.isPlaying]);

  React.useEffect(() => {
    if (playerRef.current && isLoaded) {
      playerRef.current.playbackRate = props.speed;
    }
  }, [props.speed]);

  return (
    <div
      className={`${style["audio-player"]}
       ${!props.isMuted && style["audio-player-active"]}`}
      style={{ borderRadius: props.coachNumber ? "5px" : "50%" }}
    >
      {audio && (
        <div>
          <audio
            ref={playerRef}
            src={audio.path}
            muted={props.isMuted}
            onLoadStart={() => setIsLoaded(false)}
            onCanPlayThrough={() => setIsLoaded(true)}
            onEmptied={() => setIsLoaded(false)}
            preload="auto"
            crossOrigin="anonymous"
          />
          {isLoaded ? (
            props.coachNumber ? (
              <span
                className={style["audio-player-coach"]}
                style={{ opacity: props.isMuted ? "0.5" : "1" }}
              >
                {props.coachNumber}
                <ReactSVG src="/icons/volume-up.svg" />
              </span>
            ) : (
              <AudioIcon />
            )
          ) : (
            <b>. . .</b>
          )}
          {!props.coachNumber && (
            <AudioVisualizer audio={playerRef} isMuted={props.isMuted} />
          )}
        </div>
      )}
    </div>
  );
};
