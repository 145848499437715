export const converToHours = (minutes: number) => {
  return (
    `${(minutes / 60) ^ 0}`.slice(-2) +
    ":" +
    ("0" + (minutes % 60)).slice(-2) +
    ":00"
  );
};
export const converToMinutes = (value: number) => {
  const sec = value / 1000;
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec % 3600) / 60);
  let seconds = Math.floor((sec % 3600) % 60);
  let milliFormat = String(value % 1000).padStart(3, "0");
  let milliseconds = Math.floor(parseInt(milliFormat));
  return `${hours ? hours + "" : "0"}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}.${
    milliseconds < 10 ? "0" + milliseconds : milliseconds
  }`;
};

export const converToMinutesRounded = (value: number) => {
  const sec = value / 1000;
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec % 3600) / 60);
  let seconds = Math.floor((sec % 3600) % 60);
  let milliFormat = String(value % 1000).padStart(3, "0");
  let milliseconds = Math.floor(parseInt(milliFormat));
  return `${hours ? hours + "" : "0"}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}.${
    milliseconds < 10
      ? "0" + Math.round(milliseconds / 10)
      : Math.round(milliseconds / 10)
  }`;
};

export const convertToMilliseconds = (value: string) => {
  const timeParts = value.split(":");
  const hours = parseInt(timeParts[0], 10) * 60 * 60 * 1000;
  const minutes = parseInt(timeParts[1], 10) * 60 * 1000;
  const secondsAndMilliseconds = timeParts[2].split(".");
  const seconds = parseInt(secondsAndMilliseconds[0], 10) * 1000;
  const milliseconds = parseInt(secondsAndMilliseconds[1], 10);

  return hours + minutes + seconds + milliseconds;
};
