import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LoaderComponent } from "../../components/cores/loader/loader";
import { VideoPlayerComponent } from "../../components/modules/video-player/video-player";
import { FieldAudioOverviewComponent } from "../../components/modules/field-audio-overview/field-audio-overview";
import { AddTagsComponent } from "../../components/modules/tags/add-tag";
import { TagsComponent } from "../../components/modules/tags/tags";
import { AccountContext } from "../../context/account";
import FilterContext from "../../context/filter";
import { getAPI } from "../../utils/getApi";
import style from "./recordings.module.scss";
import {
  addVideoTagsAPI,
  deleteVideoTagAPI,
  editVideoTagsAPI,
} from "../../api/tags";
import { ITag } from "../../types/cores/tag";
import { useCookies } from "react-cookie";
import { useVideoAPI, syncVideoAndAudio } from "../../api/session";
import { AudioOverviewComponent } from "../../components/modules/audio-overview/audio-overview";
import { AudioControl } from "../../components/cores/audio-control/audio-control";
import { AudioSync } from "../../components/cores/audio-sync/audio-sync";
import { convertStringToDate } from "../../utils/convertDate";
import { convertToMilliseconds } from "../../utils/convertTime";

import { LinearProgress } from "@mui/material";
import { ReactSVG } from "react-svg";

export const RecordingsPage = () => {
  const [cookies, setCookie] = useCookies(["coach", "recording", "selection"]);

  const playerRef = React.useRef<any>();
  const videoRef = React.useRef<any>();
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<any[]>([]);
  const [audios, setAudios] = React.useState<any[]>([]);
  const [video, setVideo] = React.useState<string>("");
  const [field, setField] = React.useState<any>();
  const [players, setPlayers] = React.useState<any>([]);
  const [startsAt, setStartsAt] = React.useState<any>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = React.useState<any[]>([]);
  const [sessionDate, setSessionDate] = React.useState<number>();
  const { team, startDate, endDate } = React.useContext(FilterContext);
  const { getAccount } = React.useContext(AccountContext);
  const { id } = useParams();

  const [videoDone, setVideoDone] = React.useState<boolean>(false);
  const [errorCounter, setErrorCounter] = React.useState<number>(0);
  const { uploadFile, progress } = useVideoAPI();
  const onUpload = OnUpload();
  const onError = OnError();

  const getActivePlayersId = () => {
    const playersID = players
      .map((player: any) => {
        if (!player.isMuted && player.profile.id) return player.profile.id;
      })
      .filter((id: string) => id);
    return playersID;
  };
  const onTagClicked = (tag: ITag) => {
    playerRef.current.currentTime = tag.tagTime;
    const newPlayers = players.map((player: any) => {
      if (tag.profileIds && tag.profileIds.includes(player.profile.id)) {
        player.isMuted = false;
      } else {
        player.isMuted = true;
      }

      return player;
    });
    setPlayers(newPlayers);
  };
  const getTags = async (session: any) => {
    const data = await getAPI("session/video-tags", session, "", "", "", {
      key: "sessionId",
      value: id,
    });
    setTags(data.videoTags);
  };
  const addTag = async (color: string, comment: string) => {
    if (comment) {
      getAccount().then(async (session: any) => {
        await addVideoTagsAPI(session, getActivePlayersId(), [
          {
            key: "sessionId",
            value: id,
          },
          {
            key: "comment",
            value: comment,
          },
          {
            key: "color",
            value: "%23" + color.substring(1),
          },
          {
            key: "tagTime",
            value: parseInt(startsAt),
          },
        ]);
        getTags(session);
      });
    }
  };
  const onDelete = (tagId: string) => {
    getAccount().then(async (session: any) => {
      await deleteVideoTagAPI(session, [
        {
          key: "sessionId",
          value: id,
        },
        {
          key: "videoTagId",
          value: tagId,
        },
      ]);
      getTags(session);
    });
  };
  const onEdit = (tag: ITag) => {
    getAccount().then(async (session: any) => {
      await editVideoTagsAPI(session, getActivePlayersId(), [
        {
          key: "sessionId",
          value: id,
        },
        {
          key: "videoTagId",
          value: tag.id,
        },
        {
          key: "comment",
          value: tag.comment,
        },
        {
          key: "color",
          value: "%23" + tag.color.substring(1),
        },
        {
          key: "tagTime",
          value: tag.tagTime,
        },
      ]);
      getTags(session);
    });
  };
  const getFieldData = async (session: any) => {
    const data = await getAPI(
      "session",
      session,
      team && team.id,
      startDate,
      endDate,
      [{ key: "sessionId", value: id }]
    );
    return data;
  };
  const getPlayerAudio = (players: any[], list: any[]) => {
    if (players != null) {
      const newList = list.map((item: any, index) => {
        const profile = players.find(
          (player: any) => player.id === item.profile.id
        );
        if (profile) {
          item.profile = profile;
        }
        return { ...item, isMuted: true };
      });
      setPlayers(newList);
    }
  };
  const getVideoData = async (session: any) => {
    const data = await getAPI("session/video-audio", session, "", "", "", {
      key: "sessionId",
      value: id,
    });
    const fieldData = await getFieldData(session);
    setSessionDate(fieldData.session.creationTimestamp);
    setField(fieldData.sessionAggregations[4].data);
    getPlayerAudio(
      fieldData.sessionAggregations[4].profiles,
      data.videoSyncData.profileAudioRecordingData
    );
    setVideo(data.videoSyncData.videoData.path);
    setAudios(data.videoSyncData.profileAudioRecordingData);
  };

  function OnUpload() {
    const onUpload = useMemo(
      () => async (files: any) => {
        setIsLoading(true);
        getAccount().then(async (session: any) => {
          await uploadFile(session, files[0], id);
          await new Promise((resolve) => {
            setTimeout(() => resolve("success"), 5000);
          });
          getAccount().then((session: any) => {
            getVideoData(session);
            getTags(session);
          });
          setIsLoading(false);
        });
      },
      []
    );
    return onUpload;
  }

  function OnError() {
    const onError = async (time: number) => {
      if (errorCounter <= 3) {
        getAccount().then((session: any) => {
          getVideoData(session);
          getTags(session);
          playerRef.current.currentTime = time;
        });
        setErrorCounter(errorCounter + 1);
      }
    };
    return onError;
  }

  const checkProfileName = (profile: any) => {
    if (!currentSelection?.length) {
      setCurrentSelection([profile]);
    } else {
      const hasDifferentIndex = currentSelection.some(
        (selection) => selection.audioIndex !== profile.audioIndex
      );
      const isProfileDuplicate = currentSelection.some(
        (selection) =>
          selection.name === profile.name &&
          selection.audioIndex === profile.audioIndex
      );
      if (
        !isProfileDuplicate &&
        hasDifferentIndex &&
        currentSelection.length <= 1
      ) {
        setCurrentSelection((prev) => [...prev, profile]);
      }
    }
  };
  const onAudioDelete = (profile: any) => {
    if (!currentSelection) {
      return;
    }
    const updatedSelection = currentSelection.filter(
      (selection) =>
        selection.audioIndex !== profile.audioIndex ||
        selection.name !== profile.name
    );
    if (cookies.selection) {
      const updatedCookie = cookies.selection.filter(
        (item: any) => item.id !== profile.audio.recordingId
      );
      setCookie("selection", updatedCookie, { path: "/" });
    }
    setCurrentSelection(updatedSelection);
  };
  const updateSelection = (
    profile: any,
    audioStartAt: number,
    videoStartAt: number
  ) => {
    const updatedSelection = currentSelection.map((selection) => {
      return selection.audio.recordingId === profile.recordingId
        ? {
            ...selection,
            audio: { ...profile, path: selection.audio.path },
            audioStartAt,
            videoStartAt,
            shouldPlay: !isPlaying,
          }
        : selection;
    });
    setCurrentSelection(updatedSelection);
  };
  const cacheSelection = (
    id: string,
    videoPoint: string,
    audioPoint: string
  ) => {
    const isSelectionDuplicate = cookies.selection?.some(
      (selection: any) => selection.id === id
    );

    if (isSelectionDuplicate) {
      const updatedList = cookies.selection?.map((selection: any) => {
        if (selection.id === id) {
          return {
            ...selection,
            videoStartAt: convertToMilliseconds(videoPoint),
            audioStartAt: convertToMilliseconds(audioPoint),
          };
        } else {
          return selection;
        }
      });

      setCookie("selection", JSON.stringify(updatedList), { path: "/" });
    } else {
      const selectionObject = {
        videoStartAt: convertToMilliseconds(videoPoint),
        audioStartAt: convertToMilliseconds(audioPoint),
        id: id,
      };

      const updatedList = [...(cookies.selection || []), selectionObject];
      setCookie("selection", JSON.stringify(updatedList), { path: "/" });
    }
  };
  const onSaveAndPlay = (
    videoPoint: string,
    audioPoint: string,
    audioId: string,
    audioIndex: number,
    totalAudios: number
  ) => {
    if (videoPoint && audioPoint && audioId && sessionDate) {
      const audioSync = convertStringToDate(audioPoint, sessionDate);
      const videoSync = convertStringToDate(videoPoint, sessionDate);

      getAccount().then(async (session: any) => {
        const result = await syncVideoAndAudio(session, [
          {
            key: "sessionId",
            value: id,
          },
          {
            key: "recordingId",
            value: audioId,
          },
          {
            key: "videoSyncPoint",
            value: convertToMilliseconds(videoPoint),
          },
          {
            key: "audioSyncPoint",
            value: convertToMilliseconds(audioPoint),
          },
          {
            key: "sectionNumber",
            value: audioIndex,
          },
          {
            key: "totalSections",
            value: totalAudios,
          },
        ]);

        if (result) {
          updateSelection(
            result.audioRecordingDataList[0],
            convertToMilliseconds(audioPoint),
            convertToMilliseconds(videoPoint)
          );
          playerRef.current.currentTime =
            convertToMilliseconds(videoPoint) / 1000 - 2;
          if (!isPlaying) {
            playerRef.current.play();
            cacheSelection(
              result.audioRecordingDataList[0].recordingId,
              videoPoint,
              audioPoint
            );
          } else {
            playerRef.current.pause();
          }
        }
      });
      setIsPlaying(!isPlaying);
    }
  };
  const getCachedSelections = () => {
    const selectedRecordings = [];

    for (const item of players) {
      const recordingsForPlayer = item.audioRecordingData.map(
        (rec: any, index: number) => {
          const cookie =
            cookies.selection &&
            cookies.selection?.find((sel: any) => sel.id === rec.recordingId);
          if (cookie) {
            return {
              audioIndex: index + 1,
              audio: rec,
              name: `${item.profile.firstName} ${item.profile.lastName}`,
              audioStartAt: cookie.audioStartAt,
              videoStartAt: cookie.videoStartAt,
            };
          }
          return null;
        }
      );

      const filteredRecordings = recordingsForPlayer.filter(
        (rec: any) => rec !== null
      );
      selectedRecordings.push(...filteredRecordings);
    }
    setCurrentSelection(selectedRecordings);
  };
    const getVideoAudioSyncPoints = () => {
    getAccount().then((session: any) => {
      const selectedRecordings: any[] = [];
      const data = getAPI("session/video-audio-sync-point", session, "", "", "", {
            key: "sessionId",
            value: id,
          }).then((result: any) => {
            for (const item of players) {
                  const recordingsForPlayer = item.audioRecordingData.map(
                    (rec: any, index: number) => {
                      const cookie =
                        result.audioVideoSyncPoints &&
                        result.audioVideoSyncPoints?.find((sel: any) => sel.recordingId === rec.recordingId);
                      if (cookie) {
                        return {
                          audioIndex: index + 1,
                          audio: rec,
                          name: `${item.profile.firstName} ${item.profile.lastName}`,
                          audioStartAt: cookie.audioSyncPoint,
                          videoStartAt: cookie.videoSyncPoint,
                        };
                      }
                      return null;
                    }
                  );

                  const filteredRecordings = recordingsForPlayer.filter(
                    (rec: any) => rec !== null
                  );
                  selectedRecordings.push(...filteredRecordings);
                }
                setCurrentSelection(selectedRecordings);
          });
      });
    };
  React.useEffect(() => {
    getAccount().then((session: any) => {
      getVideoData(session);
      getTags(session);
    });
  }, []);

  React.useEffect(() => {
    if (cookies.recording?.value && players.length != 0) {
        getVideoAudioSyncPoints();
    }
  }, [cookies.recording?.value, players]);

  React.useEffect(() => {
  if (!videoDone) {
      if (playerRef?.current) {
          if (!playerRef?.current.paused) {
            setVideoDone(true);
          }
      }
  }
  //setIsPlaying(!playerRef.current.paused);
  }, [playerRef.current?.paused]);

  return (
    <div className={style["recordings"]}>
      {(video || audios) && (
        <div className={style["recordings-container"]}>
          <div className={style["recordings-video"]} ref={videoRef}>
            {!isLoading ? (
              <VideoPlayerComponent
                src={video}
                hasControl
                isPlaying={isPlaying}
                startAt={startsAt}
                playerRef={playerRef}
                onChange={setStartsAt}
                onClick={setIsPlaying}
                onUpload={onUpload}
                onErrorLoading={onError}
                tags={tags}
              />
            ) : (
              <div className="widget-container" style={{ minHeight: "400px" }}>
                <LoaderComponent />
                <div hidden={progress !== -1 ? true : false}>
                  <p> Error: Something went wrong! </p>
                </div>
                <div hidden={progress >= 99 ? true : false}>
                  <p> Upload progressing... </p>
                </div>
                <div hidden={progress !== 99 ? true : false}>
                  <p> Waiting for server response... </p>
                </div>
                <div hidden={progress !== 100 ? true : false}>
                  <ReactSVG src="/icons/check.svg" />{" "}
                  <p> Success! Loading video... </p>
                </div>
                <LinearProgress variant="determinate" value={progress} />
              </div>
            )}
            {!cookies.recording?.value && (
              <TagsComponent
                tags={tags}
                onDelete={onDelete}
                onEdit={onEdit}
                onClick={onTagClicked}
              />
            )}
          </div>
          {field &&
            players &&
            (videoDone && !cookies.recording?.value ? (
              <div
                className={` ${style["recordings-field-container"]} widget-container`}
              >
                <FieldAudioOverviewComponent
                  fieldOverview={field}
                  profiles={players}
                  isCoach={cookies.coach?.value}
                  currentTime={parseInt(startsAt) * 1000}
                  isPlaying={isPlaying}
                  onChange={setPlayers}
                  realTime={playerRef.current.currentTime}
                  speed={playerRef.current.playbackRate}
                />
                <AddTagsComponent
                  time={parseInt(startsAt) * 1000}
                  onSave={addTag}
                  onClick={() => playerRef.current.pause()}
                />
              </div>
            ) : cookies.recording?.value ? (
              <div className={style["recordings-field-container"]}>
                <AudioOverviewComponent
                  profiles={players}
                  sessionLength={field?.matchData.endMinute}
                  onSelect={checkProfileName}
                  playerRef={playerRef.current}
                  height={videoRef.current?.offsetHeight}
                />
              </div>) : <div> </div>
            )}
          {cookies.recording?.value && (
            <div className={style["recordings-bottom"]}>
              {currentSelection
                .sort((a, b) => (a.audioIndex >= b.audioIndex ? 1 : -1))
                ?.map((selection, key) => (
                  <div key={key} className={style["recordings-current"]}>
                    <AudioControl
                      profile={selection}
                      onDelete={onAudioDelete}
                      startAt={selection.audioStartAt}
                      shouldPlay={selection.shouldPlay}
                    />
                    <AudioSync
                      audioStartAt={selection.audioStartAt}
                      videoStartAt={selection.videoStartAt}
                      onPlay={(video, audio) =>
                        onSaveAndPlay(
                          video,
                          audio,
                          selection.audio.recordingId,
                          selection.audioIndex,
                          2
                        )
                      }
                      isPlaying={isPlaying}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
