export const converToDate = (date: string) => {
  const result = new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });
  const day = new Date(date).getDate();
  const addition = day === 1 ? "st" : day === 2 ? "nd" : "th";
  return result + addition;
};
export const convertDateToString = (date: Date) => {
  const result = new Date(date).toLocaleDateString("fr-CA");
  return result;
};
export const convertStringToDate = (value: string, timestamp: number) => {
  const currentDate = new Date(timestamp);

  // Extract the time components from the input string
  const [hours, minutes, secondsAndMilliseconds] = value.split(":");
  const [seconds, milliseconds] = secondsAndMilliseconds.split(".");

  // Set the time components to the current date
  currentDate.setHours(parseInt(hours));
  currentDate.setMinutes(parseInt(minutes));
  currentDate.setSeconds(parseInt(seconds));
  currentDate.setMilliseconds(parseInt(milliseconds || "0"));

  // Format the date into the desired format
  const formattedDate = currentDate
    .toISOString()
    .replace("T", " ")
    .slice(0, -1);

  return formattedDate;
};
