import { ReactSVG } from "react-svg";
import style from "./audio-sync.module.scss";
import React from "react";
import { CustomInput } from "./custom-input";
interface IAudioSync {
  onPlay: (videoPoint: string, audioPoint: string) => void;
  isPlaying: boolean;
  videoStartAt?: number;
  audioStartAt?: number;
}
export const AudioSync = (props: IAudioSync) => {
  const [videoPoint, setVideoPoint] = React.useState("");
  const [audioPoint, setAudioPoint] = React.useState("");
  const [play, setPlay] = React.useState(true);

  return (
    <div className={style["audio-sync"]}>
      <div className={style["audio-sync-input"]}>
        <div>
          <label>Video sync point:</label>
          <CustomInput
            onChange={setVideoPoint}
            startAt={props.videoStartAt ?? 0}
          />
        </div>
        <div>
          <label>Audio sync point:</label>
          <CustomInput
            onChange={setAudioPoint}
            startAt={props.audioStartAt ?? 0}
          />
        </div>
      </div>
      <div className={style["audio-sync-button"]}>
        <ReactSVG
          src={`/icons/${play ? "play.svg" : "pause.svg"}`}
          onClick={() => {
            props.onPlay(videoPoint, audioPoint);
            setPlay(!play);
          }}
        />
        <small>Save and Listen</small>
      </div>
    </div>
  );
};
