import React from "react";
import { ReactSVG } from "react-svg";
import { Range } from "react-range";
import style from "./video-controls.module.scss";
import {
  converToMinutes,
  converToMinutesRounded,
} from "../../../utils/convertTime";
import { VolumeComponent } from "./volume";
import { ITag } from "../../../types/cores/tag";

interface IVideoControlsComponent {
  tags: ITag[];
  video: any;
  onChange: (time: number) => void;
}
export const VideoControlsComponent = (props: IVideoControlsComponent) => {
  const openFullscreen = () => {
    if (props.video.requestFullscreen) {
      props.video.requestFullscreen();
    } else if (props.video.webkitRequestFullscreen) {
      props.video.webkitRequestFullscreen();
    }
  };
  const getTagPosition = (value: number) => {
    return (value * 100) / props.video.duration;
  };
  React.useEffect(() => {
    props.video && props.video.load();
    props.video && (props.video.volume = 0);
  }, [props.video]);

  return props.video && props.video.readyState !== 0 ? (
    <div className={style["video-controls"]}>
      <div className={style["video-controls-top"]}>
        <small>
          {props.video
            ? converToMinutesRounded(props.video.currentTime * 1000)
            : "-"}
        </small>
        <div className={style["video-controls-progress"]}>
          {props.tags && (
            <div>
              {props.tags.map((tag, key) => (
                <span
                  key={key}
                  style={{
                    borderTopColor: tag.color,
                    left: `calc(${getTagPosition(tag.tagTime) + "%"}  - 8px)`,
                  }}
                  onClick={() => (props.video.currentTime = tag.tagTime)}
                ></span>
              ))}
            </div>
          )}
          <Range
            min={0}
            max={props.video.duration}
            values={
              props.video && props.video.currentTime
                ? [props.video.currentTime]
                : [0]
            }
            onChange={(values) => values && props.onChange(values[0])}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "100%",
                  borderRadius: "5px",
                  backgroundColor: "#c4c4c4",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  marginLeft: "-5px",
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                }}
              />
            )}
          />
        </div>
        <small>
          {props.video
            ? converToMinutesRounded(props.video.duration * 1000)
            : "-"}
        </small>
      </div>
      <div className={style["video-controls-bottom"]}>
        <div>
          <span
            onClick={() =>
              (props.video.currentTime = props.video.currentTime - 10)
            }
          >
            <ReactSVG src="/icons/back.svg" />
          </span>
          <span
            className={style["big-icon"]}
            onClick={() =>
              props.video.paused ? props.video.play() : props.video.pause()
            }
          >
            <ReactSVG
              src={`/icons/${
                props.video && !props.video.paused ? "pause.svg" : "play.svg"
              }`}
            />
          </span>
          <span
            onClick={() =>
              (props.video.currentTime = props.video.currentTime + 10)
            }
          >
            <ReactSVG src="/icons/forward.svg" />
          </span>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              props.video.playbackRate === 1
                ? (props.video.playbackRate = 2)
                : (props.video.playbackRate = 1)
            }
          >
            <b>{props.video && props.video.playbackRate + "X"}</b>
          </span>
        </div>
        <div>
          <VolumeComponent onChange={(e) => (props.video.volume = e)} />
          <span onClick={openFullscreen}>
            <ReactSVG src="/icons/fullscreen.svg" />
          </span>
        </div>
      </div>
    </div>
  ) : (
    <span></span>
  );
};
