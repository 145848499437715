import React from "react";
import { ISession } from "../types/modules/session";
import axios from "axios";
import { getURL } from "../utils/getFilterUrl";
const API_URL = process.env.REACT_APP_API_URL;

export const updateSessionTitleAPI = async (
  { accessToken }: any,
  filter: string
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.jwtToken}`,
    },
  };
  return await axios
    .put(`${API_URL}session/?${filter}`, {}, config)
    .then((response: any) => response.data)
    .catch(console.log);
};
export const syncVideoAndAudio = async (
  { accessToken }: any,
  filters: any[]
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.jwtToken}`,
    },
  };
  // const body = {
  //   sessionId: sessionId,
  //   recordingId: audioId,
  //   videoSyncPoint: videoPoint,
  //   audioSyncPoint: audioPoint,
  //   sectionNumber: audioIndex,
  //   totalSections: totalAudios,
  // };
  return await axios
    .post(
      `${API_URL}session/set-video-audio-sync-based-on-sync-point?` +
        getURL(filters),
      {},

      config
    )
    .then((response: any) => response.data)
    .catch(console.log);
};
export const useVideoAPI = () => {
    const [progress, setProgress] = React.useState(0);

    const uploadFile = async (
      { accessToken }: any,
      file: any,
      sessionId?: string
    ) => {
      let formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken.jwtToken}`,
        },
        onUploadProgress: (progressEvent: any) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 99;
            setProgress(progress);
        },
      };
      await axios
        .post(
          `${API_URL}session/upload-session-video?sessionId=` + sessionId,
          formData,
          config
        )
        .catch(function (e) {
              //handle error
              console.log("Something went wrong!: ");
              setProgress(-1);
              //console.log(e);
              return e;
            })
            .catch(console.log);
        setProgress(100);
    }
    return { uploadFile, progress };
};
// export const sessionsTableData = (): ISession[] => [
//   {
//     creationTimestamp: "01/05/2022",
//     type: "match",
//     length: 65,
//     coach: "coach name 1",
//     participants: [{admin:false,club:{},id:"wedwqeq",}],
//     recordings: true,
//     vokaloLive: true,
//   },
//   {
//     creationTimestamp: "05/05/2022",
//     type: "match",
//     length: 85,
//     coach: "coach name 2",
//     participants: ["athletes2", "athletes2", "athletes2"],
//     recordings: true,
//     vokaloLive: true,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 3",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 4",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 5",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 6",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 7",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
//   {
//     creationTimestamp: "02/05/2022",
//     type: "practice",
//     length: 165,
//     coach: "coach name 8",
//     participants: ["athletes3", "athletes2"],
//     recordings: false,
//     vokaloLive: false,
//   },
// ];
export const coachSessionsData = (): ISession[] => [
  // {
  //   id: "",
  //   creationTimestamp: "05/05/2022",
  //   type: "match",
  //   vokaloLive: true,
  //   recordings: true,
  //   score: { trendDirection: "POSITIVE" },
  // },
  // {
  //   id: "",
  //   creationTimestamp: "12/02/2022",
  //   type: "match",
  //   vokaloLive: false,
  //   recordings: true,
  //   score: { trendDirection: "NEGATIVE" },
  // },
  // {
  //   id: "",
  //   creationTimestamp: "01/01/2022",
  //   type: "match",
  //   vokaloLive: false,
  //   recordings: false,
  //   score: { trendDirection: "NEGATIVE" },
  // },
  // {
  //   id: "",
  //   creationTimestamp: "01/01/2022",
  //   type: "match",
  //   vokaloLive: true,
  //   recordings: true,
  //   score: { trendDirection: "NEGATIVE" },
  // },
];
export const squadSessionsData = (): ISession[] => [
  { id: "", creationTimestamp: "05/05/2022", type: "match", length: 25 },
  { id: "", creationTimestamp: "12/02/2022", type: "match", length: 85 },
  { id: "", creationTimestamp: "01/01/2022", type: "match", length: 155 },
];

// export const getSessionsAPI = async (
//   { accessToken }: any,
//   team: string,
//   startDate: any,
//   endDate: any,
//   filter: any
// ) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken.jwtToken}`,
//     },
//   };
//   let filtersList = getFilterUrl(team, startDate, endDate, filter);
//   return await axios
//     .get(
//       `https://data.stage.vokaloio.com/v1/platform/sessions/${filtersList}`,
//       config
//     )
//     .then((response: any) => response.data)
//     .catch(console.log);
// };
// export const getSessionDetailsAPI = async (
//   url: string,
//   { accessToken }: any,
//   team: string,
//   startDate: any,
//   endDate: any,
//   filter: any
// ) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken.jwtToken}`,
//     },
//   };
//   let filtersList = getFilterUrl(team, startDate, endDate, filter);
//   return await axios
//     .get(
//       `https://data.stage.vokaloio.com/v1/platform/${url}${filtersList}`,
//       config
//     )
//     .then((response: any) => response.data)
//     .catch(console.log);
// };
