import React from "react";
import style from "./audio-overview.module.scss";
import { IPlayer } from "../../../types/cores/player";
import { IAudio, IFieldOverview } from "../../../types/modules/field-overview";
import { AudioIcon } from "../../cores/audio-icon/audio-icon";
import {
  converToMinutes,
  converToMinutesRounded,
} from "../../../utils/convertTime";

interface IAudioOverview {
  profiles: any[];
  sessionLength: number;
  height: number;
  playerRef: any;
  onSelect: (player: any) => void;
}
export const AudioOverviewComponent = (props: IAudioOverview) => {
  const secondPart = 45 * 60000;
  const matchLenght = (props.sessionLength * 60) / 2;

  const getAudioWidth = (audio: IAudio) => {
    const audioLenght =
      (audio.endOffset - Math.max(0, audio.startOffset)) / 1000;
    const width = Math.abs(audioLenght * 100) / matchLenght;
    return Math.round(width) > 100 ? 100 : Math.round(width);
  };
  const getAudioPosition = (audio: IAudio) => {
    const isSecondPart = Math.max(0, audio.startOffset) >= secondPart;
    const difference = isSecondPart
      ? Math.max(0, audio.startOffset) - secondPart
      : Math.max(0, audio.startOffset);
    const differenceInSec = difference / 1000;
    const length = Math.abs(differenceInSec * 100) / matchLenght;
    const margin =
      getAudioWidth(audio) + Math.round(length) <= 100
        ? Math.round(length)
        : 100 - getAudioWidth(audio);
    return margin;
  };
  const addEmptyPart = (audios: IAudio[]) => {
    return audios.map(
      (audio: IAudio, index: number) =>
        audios.length === 1 &&
        audio.startOffset >= secondPart && (
          <div className={style["audio-overview-audios-item"]} key={index} />
        )
    );
  };
  const getCurrentProfile = (
    player: IPlayer,
    audio: IAudio,
    audioIndex: number
  ) => {
    const fullName: string = player.firstName + " " + player.lastName;
    props.onSelect({ name: fullName, audio, audioIndex });
  };
  return (
    <div className={style["audio-overview"]}>
      <div
        className={` ${style["audio-overview-top"]}  ${style["audio-overview-row"]}`}
      >
        Session time : <u>00:00:00:00</u> -
        <u>
          {props.playerRef?.duration
            ? converToMinutesRounded(props.playerRef.duration * 1000)
            : "00:00:00:00"}
        </u>
      </div>
      <div
        className={` ${style["audio-overview-header"]}  ${style["audio-overview-row"]}`}
      >
        <div className={style["audio-overview-player"]}>Players</div>
        <div>Recording 1</div>
        <div>Recording 2</div>
      </div>
      <div
        className={style["audio-overview-content"]}
        style={{ maxHeight: props.height - 80 + "px" }}
      >
        {props.profiles.map((player, key) => (
          <div key={key} className={style["audio-overview-row"]}>
            <div className={style["audio-overview-player"]}>
              {player.profile.firstName[0]}.{player.profile.lastName}
            </div>
            <div className={style["audio-overview-audios"]}>
              {addEmptyPart(player.audioRecordingData)}
              {player.audioRecordingData.map(
                (audio: IAudio, index: number) =>
                  getAudioWidth(audio) && (
                    <div
                      className={style["audio-overview-audios-item"]}
                      key={index}
                    >
                      <div
                        style={{
                          width: getAudioWidth(audio) + "%",
                          marginLeft: getAudioPosition(audio) + "%",
                        }}
                        onClick={() =>
                          getCurrentProfile(player.profile, audio, index + 1)
                        }
                      >
                        <AudioIcon />
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
