import React, { useRef, useEffect, useState } from "react";
import style from "./waveform.module.scss";
import Wavesurfer from "wavesurfer.js";
import { ReactSVG } from "react-svg";
import {
  converToMinutes,
  converToMinutesRounded,
} from "../../../utils/convertTime";

interface IWaveFormComponent {
  audio: any;
  startAt?: number;
  shouldPlay?: boolean;
}

export const WaveFormComponent: React.FC<IWaveFormComponent> = ({
  audio,
  startAt,
  shouldPlay,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const waveform = useRef<any>(null);
  const newStart = startAt ? startAt / 1000 - 2 : 0;

  useEffect(() => {
    const initWaveform = () => {
      if (containerRef.current && !waveform.current) {
        waveform.current = Wavesurfer.create({
          container: containerRef.current,
          waveColor: "#333333",
          barGap: 2,
          barWidth: 3,
          barRadius: 3,
          cursorWidth: 4,
          cursorColor: "#0e2e86",
          height: 30,
          hideScrollbar: true,
        });
        waveform.current.load(audio.path);
        waveform.current.on("audioprocess", () => {
          setCurrentTime(waveform.current.getCurrentTime());
        });

        waveform.current.on("ready", () => {
          waveform.current.setCurrentTime(newStart);
          setCurrentTime(newStart);
          setIsPlaying(false);
        });

        if (waveform.current.enableDragSelection) {
          waveform.current.enableDragSelection({
            maxLength: 40,
          });
        }
      }
    };

    initWaveform();

    return () => {
      if (waveform.current) {
        waveform.current.destroy();
      }
    };
  }, [audio.path]);

  useEffect(() => {
    const wavesurfer = waveform.current!;
    if (wavesurfer) {
      if (shouldPlay) {
        wavesurfer.play();
        wavesurfer.setCurrentTime(newStart);
        setCurrentTime(newStart);
        setIsPlaying(true);
      } else {
        wavesurfer.pause();
        setIsPlaying(false);
      }
    }
  }, [shouldPlay]);

  const setSpeed = (value: number) => {
    const wavesurfer = waveform.current!;
    wavesurfer.pause();
    wavesurfer.setPlaybackRate(value);
    wavesurfer.play();
  };

  const togglePlay = () => {
    if (waveform.current) {
      if (waveform.current.isPlaying()) {
        waveform.current.pause();
        setIsPlaying(false);
      } else {
        waveform.current.play();
        setIsPlaying(true);
      }
    }
  };

  const handleBackClick = () => {
    if (waveform.current) {
      const currentTime = waveform.current.getCurrentTime();
      waveform.current.setCurrentTime(currentTime - 10);
    }
  };
  const handleForwardClick = () => {
    if (waveform.current) {
      const currentTime = waveform.current.getCurrentTime();
      waveform.current.setCurrentTime(currentTime + 10);
    }
  };

  const playbackSpeeds = React.useMemo(() => [0.5, 1, 2], []);

  return (
    <div className={style["wave-form"]}>
      <div ref={containerRef} className={style["wave-form-container"]} />
      <div className={style["wave-form-time"]}>
        <span>
          {currentTime
            ? converToMinutesRounded(currentTime * 1000)
            : "00:00:00:00"}
        </span>
        <div className={style["wave-form-buttons"]}>
          <div>
            <span onClick={handleBackClick}>
              <ReactSVG src="/icons/back.svg" />
            </span>
            <span className={style["big-icon"]} onClick={togglePlay}>
              <ReactSVG
                src={`/icons/${isPlaying ? "pause.svg" : "play.svg"}`}
              />
            </span>
            <span onClick={handleForwardClick}>
              <ReactSVG src="/icons/forward.svg" />
            </span>
          </div>
          <div>
            {playbackSpeeds.map((speed) => (
              <span key={speed} onClick={() => setSpeed(speed)}>
                {`x${speed}`}
              </span>
            ))}
          </div>
        </div>
        <span>
          {audio
            ? converToMinutesRounded(audio.endOffset - audio.startOffset)
            : "00:00:00:00"}
        </span>
      </div>
    </div>
  );
};
