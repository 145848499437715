import React from "react";
import style from "./video-player.module.scss";
import Dropzone from "react-dropzone";
import { ReactSVG } from "react-svg";
import { VideoControlsComponent } from "../../cores/video-controls/video-controls";
import { LoaderComponent } from "../../cores/loader/loader";
import { ITag } from "../../../types/cores/tag";

interface IVideoPlayerComponent {
  src: string;
  startAt?: number;
  hasControl?: boolean;
  isPlaying?: boolean;
  tags: ITag[];
  playerRef: any;
  onClick: (playing: boolean) => void;
  onChange: (time: number) => void;
  onUpload: (files: any) => void;
  onErrorLoading: (time: number) => void;
}

export const VideoPlayerComponent = React.memo(
  (props: IVideoPlayerComponent) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const maxSize = 5368709120;
    return (
      <div className={style["video-player"]}>
        {props.src ? (
          <div className={style["video-player-container"]}>
            <div style={{ position: "relative" }}>
              <video
                id="session-video"
                src={props.src}
                ref={props.playerRef}
                width="100%"
                height="100%"
                onCanPlay={() => {
                  setIsLoading(false);
                }}
                onPlay={() => props.onClick(true)}
                onPause={() => props.onClick(false)}
                onTimeUpdate={(e: any) => props.onChange(e.target.currentTime)}
                onWaiting={() => {
                  props.onClick(false);
                  setIsLoading(true);
                }}
                onError={(e: any) => {
                  //Error codes include: MEDIA_ERR_ABORTED: 1, MEDIA_ERR_NETWORK: 2, MEDIA_ERR_DECODE: 3, MEDIA_ERR_SRC_NOT_SUPPORTED: 4
                  //Reason for this error handler is that we change the path of the video file after compression, so we set the new path when an error occurs. (Which should be when the browser starts trying to download the next part of the video.)
                  //Legacy code now that we have CloudFront set up and CloudFront caches the original video, allowing it to stay playable in a users session until they load the page again, where the link to the compressed video will be sent instead from the backend.
                  if (e.target.error.code == 4) {
                    setIsLoading(true);
                    props.onErrorLoading(e.target.currentTime);
                  }
                }}
                onPlaying={() => props.onClick(true)}
                preload="auto"
                poster="/img/black.png"
              />
              <div className={style["video-player-button"]}>
                {isLoading ? (
                  <LoaderComponent />
                ) : (
                  props.playerRef.current.paused && (
                    <ReactSVG
                      src="/icons/play.svg"
                      onClick={() => props.playerRef.current.play()}
                    />
                  )
                )}
              </div>
            </div>
            <VideoControlsComponent
              tags={props.tags}
              video={props.playerRef.current}
              onChange={(time) => (props.playerRef.current.currentTime = time)}
            />
          </div>
        ) : (
          <div className={`${style["video-player-file"]} widget-container`}>
            <Dropzone onDrop={props.onUpload} maxSize={maxSize}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <ReactSVG src="/icons/upload.svg" />
                  <b>Select a file or drag and drop here</b>
                  <input {...getInputProps()} />
                  <p>MOV, MP4 or AVI file size no more than 5GB</p>
                  <button>select file</button>
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    );
  }
);
