import { WaveFormComponent } from "../../modules/wavefrom/waveform";
import style from "./audio-control.module.scss";
interface IAudioControl {
  profile: any;
  onDelete: (profile: any) => void;
  startAt?: number;
  shouldPlay?: boolean;
}
export const AudioControl = ({
  profile,
  startAt,
  shouldPlay,
  onDelete,
}: IAudioControl) => {
  return (
    <div className={style["audio-control"]}>
      <div className={style["audio-control-header"]}>
        <span>
          Recording {profile.audioIndex} - <u>{profile.name}</u>
        </span>
        <span onClick={() => onDelete(profile)}>X</span>
      </div>
      <div className={style["audio-control-content"]}>
        <WaveFormComponent
          audio={profile.audio}
          startAt={startAt}
          shouldPlay={shouldPlay}
        />
      </div>
    </div>
  );
};
